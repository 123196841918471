<!--库存预警-->
<template>
    <div class="mainBox">
        <!-- <div class="selectionBar">
            <div class="flex flex-ai-c  flex-wrap">
                <label>场景：</label>
                <el-input placeholder="搜索商品名称" v-model="select.search" size="small" style="width:300px !important" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="curr=1;getList()"></el-button>
                </el-input>
                
            </div>
        </div> -->

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 115px );">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="name" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column label="商品主图" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image class="tableImage" :src="domain + scope.row.image" :preview-src-list="[domain + scope.row.image]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="sku" label="商品规格" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="价格（元）" show-overflow-tooltip></el-table-column>
                <el-table-column prop="stock" label="库存" show-overflow-tooltip></el-table-column>
                <el-table-column prop="number" label="入库数" show-overflow-tooltip width="200">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.number" placeholder="入库数" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\d{0})?/)" @change="saveEnterStore(scope.row)"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {stockWarningApi,housePlatformSaveApi}  from '@/api/house.js';
    export default {
        data() {
            return {
                select:{},
                list:[],
                previewList:[],
                title:'新建入库',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //获取商品入库列表
            getList: function(){
                stockWarningApi({
                    curr:this.curr,
                    row:this.row
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },

            saveEnterStore: function(item){
                housePlatformSaveApi({
                    attr_id:item.attr_id,
                    number:item.number,
                    product_id:item.product_id,
                    code:item.code
                }).then(()=>{
                    this.$message({
                        message: '入库成功！',
                        type: 'success'
                    });
                    this.getList();
                })
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>